import React from 'react'
import Collapsible from 'react-collapsible'
import { DateTime } from 'luxon'
import Event from '../components/calendar/Event'
import { withNamespaces } from 'react-i18next'

function Calendar({ events, t, language }) {
  const monthLocale = language ? 'de' : 'en' // Locale for month names
  const now = DateTime.now()
  const currentYear = now.year
  const currentMonth = now.setLocale(monthLocale).toFormat('MMMM') // Current month name in locale

  // Group events by year and month
  const groupedEvents = events.reduce((acc, event) => {
    const date = DateTime.fromISO(event.fields.begin)
    const year = date.year
    const monthNumber = date.month // Use the numeric month (1-12)
    const monthName = date.setLocale(monthLocale).toFormat('MMMM') // Localized month name

    if (!acc[year]) acc[year] = {}
    if (!acc[year][monthNumber]) acc[year][monthNumber] = { name: monthName, events: [] }
    acc[year][monthNumber].events.push(event)

    return acc
  }, {})

  return (
    <div>
      <div id='calendar' />
      <div className='calendar-section'>
        <div className='item-calendar'>
          <h2 className='normal-headline'>{t('Kalender')}</h2>
          <div className='calendar-grid'>
            {Object.entries(groupedEvents)
              .sort(([yearA], [yearB]) => yearB - yearA) // Sort years from newest to oldest
              .map(([year, months]) => {
                const monthEntries = Object.entries(months)

                // Sorting months: Old-to-new for the current year, New-to-old for past years
                const sortedMonths =
                  year == currentYear
                    ? monthEntries.sort(([monthA], [monthB]) => monthA - monthB) // Sort months old-to-new
                    : monthEntries.sort(([monthA], [monthB]) => monthB - monthA) // Sort months new-to-old

                return year == currentYear ? (
                  // Current Year: Sort events within months old-to-new
                  sortedMonths.map(
                    ([monthNumber, { name: monthName, events: monthEvents }]) => (
                      <Collapsible
                        key={`${year}-${monthNumber}`}
                        className='month'
                        trigger={`${monthName} ${year}`}
                        open={monthName === currentMonth} // Open only the current month
                      >
                        {monthEvents
                          .sort(
                            (a, b) =>
                              DateTime.fromISO(a.fields.begin).toMillis() -
                              DateTime.fromISO(b.fields.begin).toMillis()
                          ) // Sort events old-to-new
                          .map((event) => (
                            <Event
                              key={event.fields.id}
                              event={event}
                              language={language}
                            />
                          ))}
                      </Collapsible>
                    )
                  )
                ) : (
                  // Past Years: Sort events within months new-to-old
                  <Collapsible key={year} className='year' trigger={year.toString()}>
                    {sortedMonths.map(
                      ([monthNumber, { name: monthName, events: monthEvents }]) => (
                        <Collapsible
                          key={`${year}-${monthNumber}`}
                          className='month'
                          trigger={`${monthName} ${year}`}
                        >
                          {monthEvents
                            .sort(
                              (a, b) =>
                                DateTime.fromISO(b.fields.begin).toMillis() -
                                DateTime.fromISO(a.fields.begin).toMillis()
                            ) // Sort events new-to-old
                            .map((event) => (
                              <Event
                                key={event.fields.id}
                                event={event}
                                language={language}
                              />
                            ))}
                        </Collapsible>
                      )
                    )}
                  </Collapsible>
                )
              })}
            {events.length === 0 && (
              <div className='no-event-grid'>
                <div className='no-events'>{t('no upcoming events')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(Calendar)
